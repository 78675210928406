import LoginActionTypes from '../Utility/LoginActionTypes'
import AllActionTypes from '../../../Utility/AllActionTypes'
import { get, post, put, del } from '../../../Utility/Http';

export const setToken = (token, accessToken, expiresIn, refreshToken, session = '') => async (dispatch) => {
    // console.log('setToken dispatch', token, accessToken, expiresIn, refreshToken, session);
    dispatch({
        type: LoginActionTypes.SET_TOKEN,
        payload: token
    });
    dispatch({
        type: LoginActionTypes.SET_ACCESS_TOKEN,
        payload: accessToken
    });
    dispatch({
        type: LoginActionTypes.SET_TOKEN_EXPIRE_TIME,
        payload: expiresIn
    });
    dispatch({
        type: LoginActionTypes.SET_REFRESH_TOKEN,
        payload: refreshToken
    });
    dispatch({
        type: LoginActionTypes.SET_SESSION,
        payload: session
    });

}


export const logOutApp = (accessToken, token) => async (dispatch) => {
    del(`${process.env.EXTENDED_URL}users/signout`, "", { "Accesstoken": "", "Authorization": "" }).then((response) => {
        dispatch({
            type: LoginActionTypes.LOGOUT,
            payload: { "token": "", "accessToken": "", "refreshToken": "", "expiresIn": { "loggedInTime": "", "expiryTime": "", "expiryInterval": "" }, "userCredentials": {}, "version": [], "roleWisePermission": {} }
        });
        dispatch({
            type: AllActionTypes.CLEAR_DATA,
            payload: { "loaderState": false, "leftbar": false, "activeLink": { 'accName': "", 'activeClass': "" }, "roleWisePermission": {} }
        });
        return response
    })
    dispatch({
        type: LoginActionTypes.LOGOUT,
        payload: { "token": "", "accessToken": "", "refreshToken": "", "expiresIn": { "loggedInTime": "", "expiryTime": "", "expiryInterval": "" }, "userCredentials": {}, "version": [], "roleWisePermission": {}, "session": "" }
    });
    dispatch({
        type: AllActionTypes.CLEAR_DATA,
        payload: { "loaderState": false, "leftbar": false, "activeLink": { 'accName': "", 'activeClass': "" }, "roleWisePermission": {} }
    });

    // dispatch({
    //     type: AllActionTypes.SELECT_BRANCH_DATA,
    //     payload: { "token": "", "accessToken": "", "refreshToken": "", "expiresIn": { "loggedInTime": "", "expiryTime": "", "expiryInterval": "" }, "userCredentials": {}, "version": [], "roleWisePermission": {} }
    // });
}

export const setUserCredentials = (value) => async (dispatch) => {
    dispatch({
        type: LoginActionTypes.SET_USER_CREDENTIALS,
        payload: value
    });

}

export const setVersionList = (value) => async (dispatch) => {
    dispatch({
        type: LoginActionTypes.SET_VERSION,
        payload: value
    });

}
