import React, { useState, useEffect } from 'react';
import CustomInput from '../../../Utility/Components/CustomInput';
import Utility from '../../../Utility/Utility';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { setToken, logOutApp } from '../Actions/LoginAction';
import { connect } from 'react-redux';
import * as LoginController from '../Controller/LoginController'
import '../Assets/css/logindoc.scss';
import '../Assets/css/loginresponsivedoc.scss';
import { withNamespaces } from 'react-i18next';
import ValidateUtility from '../../../Utility/ValidateUtility';
import { useParams } from 'react-router-dom';

const ForgotPassword = (props) => {
    const { type } = useParams();
    const [username, setusername] = useState("")
    const [phone_number, setphone_number] = useState("")

    const [state, setState] = useState({

        passwordResetCode: "",
        password: "",
        retypePassword: "",

        usernameError: "",
        passwordResetCodeError: "",
        passwordError: "",
        retypePasswordError: "",

        hideShowChangePasswordPanel: false,
        passwordLock: false,
        rePasswordLock: false,
        passType: "password",
        reTypePassType: "password"
    })

    const { loaderStateTrue, loaderStateFalse, history, logOutApp } = props
    const handleChange = (e) => {  //onchange event in forget password popup inputs
        if (e.target.name == "username") {
            if (e.target.value != "") {
                setusername(e.target.value)
                setState(prevState => ({
                    ...prevState,
                    usernameError: ""
                }));
            } else {
                setusername("")
                setState(prevState => ({
                    ...prevState,
                    usernameError: ""
                }));
            }
            setusername(e.target.value)
        }

        //change password
        if (e.target.name == "password") {
            if (e.target.value == "") {
                setState(prev => ({
                    ...prev,
                    passwordError: `${props.t("passwordError")}`
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    passwordError: ""
                }))
            }
        }
        if (e.target.name == "retypePassword") {
            if (e.target.value == "") {
                setState(prev => ({
                    ...prev,
                    retypePasswordError: `${props.t("retypepasswordError")}`
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    retypePasswordError: ""
                }))
            }
        }
        if (e.target.name == "passwordResetCode") {
            if (e.target.value == "") {
                setState(prev => ({
                    ...prev,
                    passwordResetCodeError: `${props.t("enterverificationCode")}`
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    passwordResetCodeError: ""
                }))
            }
        }

        if (e.target.name != "username") {
            setState(prev => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }

    const forgotPassword = async () => { //forgot password button click fn
        if (ValidateUtility.validate_email(username)) { //email validation check
            try {
                let data = {}
                if (username != "") {
                    data['username'] = username;  //dataset create
                }
                if (username == "") {
                    data['username'] = phone_number;
                }

                data['application_platform'] = 'customer';
                loaderStateTrue();
                LoginController.forgotPassword(data).then((response) => {
                    loaderStateFalse();
                    if (response.success) {
                        if (response.message == "Username and a temporary password have been sent to your email. please log in using the credentials and change your password.") {
                            props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
                        } else {
                            setState(prev => ({
                                ...prev,
                                hideShowChangePasswordPanel: true
                            }))
                        }
                        Utility.toastNotifications(response.message, "Success", "success");
                    } else {
                        Utility.toastNotifications(response.message, "Error", "error");
                    }
                }).catch((error) => {
                    /*if (error.response) {
                        if (error.response.status === 401) {
                            Utility.toastNotifications("Please login", "Error", "error");
                            logOutApp().then(
                                () => history.push("/")
                            );
                        }
                    }
                    if (error.message == "Network Error") {
                        Utility.toastNotifications("Please login", "Error", "error");
                        logOutApp().then(
                            () => history.push("/")
                        );
                    }*/
                    loaderStateFalse();
                });

            } catch (error) {
                if (error.code == "UserNotFoundException") {
                    Utility.toastNotifications("User not found", "Error", "error")
                } else {
                    Utility.toastNotifications(error.message, "Error", "error")
                }
                //console.log('error signing in', error.code);
            }
        } else {
            //Utility.toastNotifications(props.t('validEmail'), "Error", "error");
            setState(prev => ({
                ...prev,
                usernameError: props.t('validEmail')
            }))
        }

    }
    /*requestForNewPassword = async () => {
        const { username, passwordResetCode, password } = this.state;
        const { history } = this.props;
        if (this.validationForPasswordChange()) {
            try {
                const user = await Auth.forgotPasswordSubmit(username, passwordResetCode, password);
                Utility.toastNotifications(`Your password for ${username} change successfully`, "Success", "success");
                history.push("/");
            } catch (error) {
                Utility.toastNotifications(error.message, "Error", "error")
                //console.log('error signing in', error);
            }
        }

    }*/
    const requestForNewPassword = async () => { //change password fn
        const { passwordResetCode, password } = state;
        if (validationForPasswordChange()) {
            try {
                let data = {}
                if (username != "") {
                    data['username'] = username;
                }
                data['password'] = password;
                data['confirmation_code'] = passwordResetCode;
                loaderStateTrue();
                LoginController.changePassword(data).then((response) => {
                    loaderStateFalse();
                    if (response.success) {
                        Utility.toastNotifications(response.message, "Success", "success");
                        history.push("/");
                    } else {
                        Utility.toastNotifications(response.message, "Error", "error");
                    }

                }).catch((error) => {
                    /*if (error.response) {
                        if (error.response.status === 401) {
                            Utility.toastNotifications("Please login", "Error", "error");
                            logOutApp().then(
                                () => history.push("/")
                            );
                        }
                    }
                    if (error.message == "Network Error") {
                        Utility.toastNotifications("Please try again", "Error", "error");
                    }*/
                    loaderStateFalse();
                });

            } catch (error) {
                if (error.code == "UserNotFoundException") {
                    Utility.toastNotifications("User not found", "Error", "error")
                } else {
                    Utility.toastNotifications(error.message, "Error", "error")
                }
                //console.log('error signing in', error.code);
            }
        }

    }

    const validationForEmail = () => {
        let valid = true;
        //const { username, password, phone_number } = state;
        let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username);
        if (emailValidate || phone_number != "") {
            setState(prev => ({
                ...prev,
                usernameError: ""
            }))
        } else {
            valid = false;
            setState(prev => ({
                ...prev,
                usernameError: `${props.t('emailError')}`
            }))
        }

        return valid;
    }
    const validationForPasswordChange = () => {   //change password valid fn
        let valid = true;
        const { passwordResetCode, password, retypePassword } = state;
        if (password == "") {
            valid = false;
            setState(prev => ({
                ...prev,
                passwordError: `${props.t('passwordError')}`
            }))
        } else {
            setState(prev => ({
                ...prev,
                passwordError: ""
            }))
        }
        if (retypePassword == "") {
            valid = false;
            setState(prev => ({
                ...prev,
                retypePasswordError: `${props.t('retypepasswordError')}`
            }))
        } else {
            setState(prev => ({
                ...prev,
                retypePasswordError: ""
            }))
        }
        // if (password != "" && retypePassword != "") {
        //     let passwordValidate = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-+“!@#%&/,><\’:;|_~`])\S{6,99}$/.test(password);
        //     if (passwordValidate) {
        //         setState(prev => ({
        //             ...prev,
        //             passwordError: ''
        //         }))
        //     } else {
        //         valid = false;
        //         setState(prev => ({
        //             ...prev,
        //             passwordError: "Please provide a strong password."
        //         }))
        //     }
        //     let retypePasswordValidate = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-+“!@#%&/,><\’:;|_~`])\S{6,99}$/.test(retypePassword);
        //     if (retypePasswordValidate) {
        //         setState(prev => ({
        //             ...prev,
        //             retypePasswordError: ''
        //         }))
        //     } else {
        //         valid = false;
        //         setState(prev => ({
        //             ...prev,
        //             retypePasswordError: "Please provide a strong password."
        //         }))
        //     }
        // }
        if ((password != "" && retypePassword != "") && password != retypePassword) {
            valid = false;
            setState(prev => ({
                ...prev,
                retypePasswordError: `${props.t('passwordnotmatch')}`,
                passwordError: `${props.t('passwordnotmatch')}`
            }))
        }
        if (passwordResetCode == "") {
            valid = false;
            setState(prev => ({
                ...prev,
                passwordResetCodeError: `${props.t('enterverificationCode')}`
            }))
        } else {
            setState(prev => ({
                ...prev,
                passwordResetCodeError: ""
            }))
        }
        return valid;
    }
    const { hideShowChangePasswordPanel } = state;

    const back_to_login = () => {  //redirect to login page
        // props.history.push("/")
        props.history.push(`/en/login`);
    }

    const lanOnSelect = (lng) => {
        let confirmation = false;
        confirmation = window.confirm(`${props.t('areYouSureYouWantToReload')}`);
        if (confirmation) {
            i18n.changeLanguage(lng);
            let splitUrl = props.match.url.split('/');
            props.history.push(`/${lng}/${splitUrl[2]}`);
            window.location.reload();
        }

    }

    const hideShowEyeButton = (callFrom, type) => { //pass eye button hide show fn

        if (type == "text") {
            if (callFrom == "pass") {
                setState(prev => ({
                    ...prev,
                    passwordLock: true,
                    passType: "text"
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    rePasswordLock: true,
                    reTypePassType: "text"
                }))
            }

        } else {
            if (callFrom == "pass") {
                setState(prev => ({
                    ...prev,
                    passwordLock: false,
                    passType: "password"
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    rePasswordLock: false,
                    reTypePassType: "password"
                }))
            }
        }
    }



    useEffect(() => {
        if (props.userCredentials.hasOwnProperty('user_details') && props.userCredentials.user_details && Object.keys(props.userCredentials.user_details).length > 0) {
            props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);//initially redirection
        }
    }, []);

    useEffect(() => {
        if (ValidateUtility.validate_email(type)) { //initially email coming from url then it will set forget password email field
            setusername(type);
        }
    }, [])

    return (

        <div className="loginBackground">

            <div className='container'>
                <div className='innLoginPage forgotpassword-passhints'>
                    <div className='row'>
                        <div className={hideShowChangePasswordPanel ? "col-sm-4 d-none d-lg-block d-md-block" : "col-sm-6 d-none d-lg-block d-md-block"}>
                            <div className='leftInnLoginPage'>
                                <img src={require('../../../Utility/Public/images/resetIcon.png')} className="loginIcon" />
                            </div>
                        </div>
                        <div className={hideShowChangePasswordPanel ? "col-lg-5 col-md-5 col-sm-12" : "col-lg-6 col-md-6 col-sm-12"}>
                            <div className='rightInnLoginPage'>
                                {/* <div className={hideShowChangePasswordPanel == false ? "appContainerInner appContainerInnerForgot" : "appContainerInner appContainerInnerForgot appContainerInnerRetype"}> */}
                                <div className="left_section login_box_inner white_background forgotpass">
                                    <div className="logo-box-inner">
                                        <div className="titleposition">
                                            <h2 className="titleone">The Staying</h2>
                                            <h3 className="titletwo">Online property booking management</h3>
                                        </div>
                                    </div>
                                    <div className="itemsContainer login_left_inner">

                                        <div className="forgotFormDiv forgotDivInner">
                                            {hideShowChangePasswordPanel == false ?
                                                <div>
                                                    <CustomInput
                                                        parentClassName="loginFormInnerDiv input_field_small_size emaillabel"
                                                        onChange={handleChange}
                                                        name="username"
                                                        value={username != "" ? username : phone_number}
                                                        labelName="Email"
                                                        errorLabel={state.usernameError}
                                                        labelPresent={false}
                                                        requiredStar={false}
                                                        placeholder="Email"
                                                    />
                                                    <div className="forgor_btn_inner_div logbtndiv">
                                                        <button className="login-btn btn btn-primary" onClick={forgotPassword}>{props.t('resetPasswordButton')}</button>
                                                    </div>
                                                    <div className="back_inner_div">
                                                        <p className="backtolog" onClick={back_to_login}><span>{props.t('resetPasswordbackToLogin')}</span></p>
                                                    </div>
                                                </div> :
                                                <div>
                                                    <CustomInput
                                                        parentClassName="loginFormInnerDiv input_field_small_size email_disable_field"
                                                        name="username"
                                                        value={state.username}
                                                        //labelName="Mail"
                                                        //errorLabel={this.state.usernameError}
                                                        readOnly={true}
                                                        //labelPresent={true}
                                                        placeholder={props.t('emailIdPlaceHolder')}
                                                    />
                                                    <CustomInput
                                                        parentClassName="loginFormInnerDiv input_field_small_size"
                                                        onChange={handleChange}
                                                        name="passwordResetCode"
                                                        value={state.passwordResetCode}
                                                        //labelName="Verification code"
                                                        placeholder={props.t('Verificationcode')}
                                                        errorLabel={state.passwordResetCodeError}
                                                    //labelPresent={true}
                                                    />
                                                    <div className='positionRelative'>
                                                        <CustomInput
                                                            parentClassName="loginFormInnerDiv input_field_small_size"
                                                            onChange={handleChange}
                                                            name="password"
                                                            type={state.passType == "text" ? "text" : "password"}
                                                            value={state.password}
                                                            //labelName="New password"
                                                            errorLabel={state.passwordError}
                                                            //labelPresent={true}
                                                            placeholder={props.t('NewpasswordPlaceHolder')}
                                                        />
                                                        {!state.passwordLock ?
                                                            <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("pass", "text") }}>
                                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                            </span>

                                                            :
                                                            <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("pass", "password") }}>
                                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                                            </span>

                                                        }
                                                    </div>
                                                    <div className='positionRelative'>
                                                        <CustomInput
                                                            parentClassName="loginFormInnerDiv input_field_small_size"
                                                            onChange={handleChange}
                                                            name="retypePassword"
                                                            type={state.reTypePassType == "text" ? "text" : "password"}
                                                            value={state.retypePassword}
                                                            //labelName="Retype new password"
                                                            placeholder={props.t('RetypenewpasswordPlaceHolder')}
                                                            errorLabel={state.retypePasswordError}
                                                        //labelPresent={true}
                                                        />
                                                        {!state.rePasswordLock ?
                                                            <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypepass", "text") }}>
                                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                            </span>

                                                            :
                                                            <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypepass", "password") }}>
                                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                                            </span>

                                                        }
                                                    </div>
                                                    <div className="forgor_btnBoxes forgotpassmodify">
                                                        <div className="forgor_btn_inner_div bottom15">
                                                            <button className="login-btn btn btn-primary" onClick={requestForNewPassword}>{props.t('ChangePasswordButton')}</button>
                                                        </div>

                                                        <p className="resendverificationbtn" onClick={forgotPassword}>{props.t('ResendverificationcodeButton')}</p>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {hideShowChangePasswordPanel ?
                                        <div className='password-rules-box forgot-rule-box'>
                                            <div className='pass-right-box'>
                                                <h5>Password Hints:</h5>
                                                <ul className='password-inner-box'>
                                                    <li><span>Minimum length</span>, which must be at least 6 characters but fewer than 99 characters</li>
                                                    <li><span>Require numbers</span></li>
                                                    <li><span>Require a special character</span> {`from this set: = + - ^ $ * . [ ] { } ( ) ?  ! @ # % & / \ , > < ' : ; | _ ~ `}</li>
                                                    <li><span>Require uppercase letters</span></li>
                                                    <li><span>Require lowercase letters</span></li>
                                                </ul>
                                            </div>
                                        </div> : null}
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )

}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token,

    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, logOutApp })(withNamespaces()(ForgotPassword));