import React, { useRef, useEffect, useMemo } from 'react';

function LazyImage({ src, alt, width, height, loaderImage,className="" }) {
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const img = new Image();
                        img.src = src;
                        img.onload = () => {
                            if (imgRef.current) {
                                imgRef.current.src = src;
                                imgRef.current.style.filter = 'none'; // Remove blur effect
                                observer.unobserve(imgRef.current);
                            }
                        };
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [src]);

    return (
        <img
            ref={imgRef}
            src={loaderImage}// Blurred placeholder image
            alt={alt}
            width={width}
            height={height}
            style={{
                //display: 'block',
                //margin: 'auto',
                filter: 'blur(15px)', // Apply blur effect
                transition: 'filter 0.3s ease-in-out',
            }}
            className={className}
        />
    );
};
export default LazyImage;